import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../AppContextProvider';
import axios from 'axios';
import SummerSale from '../../Assets/SummerSale.png'
import Banner from "../../Assets/Banner.png";
import Banner1 from "../../Assets/Banner1.png";
import Banner2 from "../../Assets/Banner2.png";
import Banner3 from "../../Assets/Banner3.png";
import Banner4 from "../../Assets/Banner4.png";
// import Banner5 from "../../Assets/Banner5.png";
import './style.css'
import Testcrd from './test'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { ProductPopup } from './ProductPopup';
import ProdcutCard from './ProdcutCard';
import { capitalizeName, fetchData, fetchStoreData, cheqapi, copyToClipboard } from '../../Components/Component';



function Product({ totalproducts, setQuantity, setShowModal }) {

    let navigate = useNavigate();
    const location = useLocation();
    const [products, setJson] = useState([])
    const [ageModal, setAgeModal] = useState(false)
    const [showSlecStore, setShowSlecStore] = useState(false)
    // const [uniqueCategories, setUniqueCategories] = useState([]);
    const [procatagory, setProCatagory] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCatPop, setSelectedCatPop] = useState(false);

    useEffect(() => {
        const ageModalShown = localStorage.getItem('ageModalShown');
        const token = localStorage.getItem('token');
        if (!ageModalShown && !token) {
            setAgeModal(true);
        }
        axios.get('https://api.vapedelivery.com/api/main/cats', {
            withCredentials: false
        })
            .then(response => {
                setProCatagory(response.data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const { selectedAdress, setSelectedAdress } = useAppContext()
    useEffect(() => {
        fetchDataAsync();
    }, []);


    const fetchDataAsync = async () => {

        if (selectedAdress) {
            try {
                // let data
                await fetchStoreData(selectedAdress, setSelectedAdress);

                // setJson(selectedAdress?.products);
                // console.log(products,"products")
                // console.log(selectedAdress.products,"selected")


                // if (data?.storeName) {
                //     setJson(data?.products);
                //     // console.log(displayedProducts, "categories--145");


                // const categoryList = selectedAdress?.products?.map(item => item.category);
                // //     // Remove duplicate categories based on the category _id
                // const uniqueCategories = categoryList?.filter(
                //     (category, index, self) => index === self.findIndex((c) => c._id === category._id));
                //     setUniqueCategories(uniqueCategories);



                // } else { 
                //     //  cheqapi(); 
                // }
            } catch (error) { console.error("Error fetching data:", error); }
        } else {
            //  cheqapi();
        }
    };




    const handelClose = (e) => {
        // setShowDropdown(true);
        setShowSlecStore(false)
        navigate('/saveLocation')
    }
    const CheqAGE = (e) => {
        const age = e;
        if (age === "yes") {
            cheqapi(setSelectedAdress);
        }
        // Set flag in localStorage to indicate modal has been shown
        localStorage.setItem('ageModalShown', true);
        setAgeModal(false);
    };
    const HandelClosePopup = (e, product) => {
        setShowPopup(e)
        setSelectedProduct(product);
    };


    // Filtered products based on search query
    // const [selectedCategory, setSelectedCategory] = useState(''); // State variable for selected category
    const { selectedCategorytype, setSelectedCategorytype } = useAppContext()
    const filteredProducts = products?.filter(item => {
        // Check if searchQuery exists and filter products by item.name
        if (searchQuery) {
            // Convert both searchQuery and item.name to lowercase for case-insensitive comparison
            return item?.name.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return true; // Return all items if no search query is provided
    });

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    // const handleCategoryChange = (category) => {
    const handleCategorytypeChange = (category) => {
        setSelectedCategorytype(category);
        navigate('/selectedCatogary', { state: { products, procatagory, category } })
        // setSelectedCategory(category);
    };


    // Determine if the current page is the product page
    const displayedProducts = searchQuery ? selectedAdress?.products?.filter(item => { return item?.name?.toLowerCase().includes(searchQuery.toLowerCase()); }) : selectedAdress?.products;
    const [itemsToShow, setItemsToShow] = useState(4);
    const width = window.innerWidth;

    useEffect(() => {
        const updateItemsToShow = () => {
            if (width < 640) {
                setItemsToShow(2); // Mobile
            } else if (width >= 640 && width < 1024) {
                setItemsToShow(2); // Tablet
            } else if (width >= 1024 && width < 1440) {
                setItemsToShow(4); // Laptop
            } else if (width >= 1440) {
                setItemsToShow(4);
            }
        }; updateItemsToShow();
        window.addEventListener('resize', updateItemsToShow); // Update on resize
        return () => {
            window.removeEventListener('resize', updateItemsToShow); // Clean up event listener
        };
    }, []);
    const assetImages = [Banner,Banner1,  Banner2, Banner3, Banner4];

    const { userData, setUserData } = useAppContext();
    const shareLink = `https://vapedelivery.com/signup?referralCode=${userData?.profile?._id}`;
    const [copySuccess, setCopySuccess] = useState('');

    // const totalOrders = userData?.orders?.length || 0;
    // const totalOrders = userData?.orders
    //     ?.filter(order => {
    //         const totalAmount = order.productDetails?.reduce((sum, product) => sum + product.price, 0);
    //         return totalAmount >= 20;
    //     })
    //     ?.length || 0;

    // // Calculate the current step (each milestone is 10 orders)
    // const currentStep = Math.floor(totalOrders);
    // const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; // Define milestones

    // ---------------
    const totalOrders = userData?.orders
        ?.filter(order => {
            const totalAmount = order.productDetails?.reduce((sum, product) => sum + product.price, 0);
            return totalAmount >= 20;
        })
        ?.length || 0;

    // Reset the order count every 12 milestones
    const resetStep = totalOrders % 12; // Determines the step within the current 12-step cycle
    const steps = Array.from({ length: 12 }, (_, i) => i + 1); // Milestones 1 to 12

    // console.log(displayedProducts, "display products")

    return (
        <div className='w-full  items-center flex  justify-center bg-white'>
            <div className=' w-full   items-center justify-center flex flex-col '>
                <div className='flex flex-col justify-center gap-4 w-full items-center bg-[#2A2F3D] py-6'>
                    <div className='flex justify-center gap-4 items- bg-[#2A2F3D] py-0 w-full  overflow-x-auto md:space-x-4 sm:space-x-2  sm: scrollbar-thin '>
                        {procatagory && procatagory?.map((item, cindex) => (
                            <div onClick={() => handleCategorytypeChange(item.name)} key={cindex} className=' cursor-pointer  items-center w-15 md:w-20  flex flex-col'>
                                <div className='bg-white  rounded-full  border items-center justify-center flex flex-col'>
                                    <img height="180"
                                        width="230"
                                        className="rounded transition-transform duration-500 rounded-full"
                                        src={`https://api.vapedelivery.com/${item?.icon}`}
                                        alt=""
                                    /></div>
                                <p className='text-sm py-2 text-white font-semibold text-center'> {capitalizeName(item?.name)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex w-full flex-wrap  justify-center p-4 mb-3">
                        <input className='md:w-w/5 w-3/4 px-3 py-3 mb-8 border-2 focus:border-orange-500 border-gray-200 text-center md:text-start lg:text-start  rounded-full focus:outline-none transition-colors'
                            type="text" placeholder="Search Vape Delivery" value={searchQuery} onChange={handleSearchInputChange} />
                    </div>
                </div>

                <div style={{ margin: "-40px 0px 0px 0px" }} className='bg-[#F4F2EE] md:w-4/5 w-full m-auto self-center rounded-2xl py-2 px-0 md:px-6 xl:px-8 m-[-44px]' >
                    {/* procatagory */}
                    {selectedCatPop ?
                        <>
                            {selectedCategorytype &&
                                <div className={" w-full"} >
                                    <div className='flex justify- w-full xl:w-[22%] cursor-pointer '>
                                        <h3 onClick={() => { setSelectedCatPop(false); fetchDataAsync() }} className="flex text-sm items-center my-4 mx-2">Home
                                            <svg className='ml-2' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"                                        >
                                                <path d="M2.35742 2.33203L8.35742 8.33203L2.35742 14.332" stroke="#2A2F3D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </h3>
                                        <h1 className="font-bold xl:text-xl text-sm text-starts my-4">{capitalizeName(selectedCategorytype)}</h1>
                                    </div>
                                    <h1 className="font-bold xl:text-4xl text-xl text-start mb-4"> {capitalizeName(selectedCategorytype)}</h1>
                                    <div className="w-full flex flex-wrap justify-between md:gap-3 gap-0.5  overflow-auto  scrollbar-hide scrollbar-thin ">
                                        {displayedProducts.filter(x => x.category.name === selectedCategorytype).map((product, index) => (<ProdcutCard product={product} HandelClosePopup={HandelClosePopup} />))}
                                    </div>
                                </div>}
                        </>
                        :
                        <>
                            {procatagory && procatagory?.map((item, cindex) => (
                                <>
                                    {displayedProducts?.filter(x => x.category?.name === item?.name).length > 0 &&
                                        <div className={" w-full"} key={cindex}>
                                            <div className='flex cursor-pointer justify-between w-full px-3 mt-3'>
                                                <h1 className="font-bold xl:text-2xl sm:text-md text-start my-1 pb-0 " style={{ letterSpacing: width < 400 ? '.2rem' : '.2rem' }}>  {item.name.toUpperCase()}</h1>
                                                {/* <h3 onClick={() => handleCategorytypeChange(item.name)} className="flex text-sm items-center my-1 mx-2">All Items */}
                                                <h3 onClick={() => handleCategorytypeChange(item.name)}
                                                    className="flex text-sm items-center my-1 mx-2">All Items
                                                    <svg className='ml-2' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"                                                    >
                                                        <path d="M2.35742 2.33203L8.35742 8.33203L2.35742 14.332" stroke="#2A2F3D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </h3>
                                            </div>
                                            <div className="w-full flex justify-start overflow-x-auto space-x-4 scrollbar-hide items-start">
                                                {displayedProducts?.filter(x => x.category?.name === item?.name)
                                                    .map((product, index) => (<ProdcutCard product={product} HandelClosePopup={HandelClosePopup} setShowSlecStore={setShowSlecStore} />))}
                                            </div>
                                            {cindex === 1 &&
                                                <div className='mt-2'>
                                                    <Swiper
                                                        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                                                        spaceBetween={10}
                                                        slidesPerView={width < 500 ? 1 : 3}
                                                        autoplay={{
                                                            delay: 2500,
                                                            disableOnInteraction: false,
                                                        }}
                                                        className='p-2  w-full flex'
                                                        pagination={{ clickable: true }}
                                                    // scrollbar={{ draggable: true }}
                                                    // onSwiper={(swiper) => console.log('swiper')}
                                                    // onSlideChange={() => console.log('slide change')}
                                                    >

                                                        {assetImages.map((image, imgIndex) => (
                                                            <SwiperSlide>
                                                                <div onClick={() => (imgIndex === 1) && copyToClipboard(shareLink, setCopySuccess)} key={imgIndex} className=' flex cursor-pointer'><img src={image} alt={`Assetimage ${imgIndex + 1}`} style={{ width: '100%', height: 'auto' }} /></div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>

                                                    {copySuccess &&
                                                        <div class="fixed z-10 bottom-[5%] left-[5%] md:left-[30%] w-[90%] md:w-1/3 flex items-center p-2  text-[12px] md:text-sm text-green-800 rounded-lg bg-green-200  " role="alert">
                                                            <svg width="30" height="30" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="21" cy="21" r="21" fill="#bbf7d0" />
                                                                <path d="M15 20C15 17.172 15 15.757 15.879 14.879C16.757 14 18.172 14 21 14H24C26.828 14 28.243 14 29.121 14.879C30 15.757 30 17.172 30 20V25C30 27.828 30 29.243 29.121 30.121C28.243 31 26.828 31 24 31H21C18.172 31 16.757 31 15.879 30.121C15 29.243 15 27.828 15 25V20Z" stroke="currentColor" stroke-width="2" />
                                                                <path d="M15 28C14.2044 28 13.4413 27.6839 12.8787 27.1213C12.3161 26.5587 12 25.7956 12 25V19C12 15.229 12 13.343 13.172 12.172C14.344 11.001 16.229 11 20 11H24C24.7956 11 25.5587 11.3161 26.1213 11.8787C26.6839 12.4413 27 13.2044 27 14" stroke="currentColor" stroke-width="2" />
                                                            </svg>
                                                            <span class="font-semibold">Referral Code Copied 
                                                            Send it Via Texting App</span>
                                                        </div>
                                                    }
                                                </div>}

                                            {cindex === 1 && <div className="w-full  my-6 p-6 bg-[#2A2F3D] shadow rounded-lg">
                                                <div className='md:flex justify-between  md:px-12 pb-3'>
                                                    <div className='md:flex flex-col'>
                                                        <h2 className="text-[1.5rem] text-white font-bold  inline-block ">ORDER MILESTONES</h2>
                                                        <label className="text-white  font-semibold text-sm md:text-sm line-clamp-2">Keep going to earn $25 discount!</label>
                                                    </div>
                                                    <div className='flex flex-col md:items-center pt-3 md:pt-0 md:gap-x-3'>
                                                        <h2 className="md:text-lg text-xs text-white font-semibold ">Reward</h2>
                                                        <label className="text-white leading-[3rem] font-semibold  text-[2.5rem] line-clamp-2">$25</label>
                                                    </div>
                                                </div>
                                                {/* Stepper */}


                                                <div className="flex justify-center items-center md:px-12 pb-4">
                                                    {steps.map((milestone, index) => (
                                                        <div
                                                            key={index}
                                                            className={`flex items-center ${index === steps.length - 1 ? '' : 'flex-1'}`}
                                                        >
                                                            <div className="flex flex-col items-center">
                                                                <span
                                                                    className={`flex items-center justify-center md:w-12 md:h-12 h-4 w-4 rounded-md ${resetStep > index ? 'bg-white text-white' : 'bg-gray-300 text-gray-500'
                                                                        }`}
                                                                >
                                                                    {resetStep > index ? (
                                                                        <svg
                                                                            width={width < 640 ? '18' : '18'}
                                                                            height={width < 640 ? '18' : '14'}
                                                                            viewBox="0 0 23 18"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M1.6875 9.8448L7.18562 15.7648L20.8875 2.0625"
                                                                                stroke="#14AE5C"
                                                                                strokeWidth="2.74286"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        <label className="block text-gray-500 font-semibold text-xs md:text-sm line-clamp-2"></label>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {/* {index < steps.length - 1 && (
                                                                <div
                                                                    className={`h-1 w-full ${resetStep > index ? 'bg-orange-500' : 'bg-gray-300'
                                                                        }`}
                                                                />
                                                            )} */}
                                                        </div>
                                                    ))}
                                                </div>
                                                <label className="text-white md:px-12 pb-1 font-semibold text-xs md:text-sm line-clamp-2">
                                                    {totalOrders % 12 === 0 && totalOrders !== 0
                                                        ? 'Milestones Completed!'
                                                        : `${12 - (totalOrders % 12)} orders left to get reward`}
                                                </label>
                                                {/* <label className="text-white md:px-12 pb-1 font-semibold text-xs md:text-sm line-clamp-2">{totalOrders > 12 ? 'Milestones Completed!' : `${12 - totalOrders} orders left to get reward`}</label> */}
                                                {/* <div className='md:flex justify-between items-center'>
                                                    <label className="md:w-1/3 block text-gray-500 font-semibold text-xs md:text-sm line-clamp-2">Total Orders: {totalOrders} </label>
                                                    <label className="md:w-1/3 text-center text-green-600 font-semibold">
                                                        {currentStep >= 12 ? `🎉 You've earned a $25 discount for reaching ${currentStep} orders!` : `Keep going to earn $25 discount!`}
                                                    </label>
                                                    <label className="block md:w-1/3 md:text-end animate-pulse text-red-500 font-semibold text-xs md:text-sm line-clamp-2">{currentStep >= 11 && 'Earn a discount by placing 12 orders, each valued at over $20.'}</label>
                                                </div> */}
                                            </div>}
                                        </div >}
                                </>
                            ))}
                        </>
                    }
                </div>
                {showPopup && <ProductPopup setShowPopup={setShowPopup} setShowModal={setShowModal} selectedProduct={selectedProduct} />}
            </div >
            {/* SHow stor avalibility popup */}
            {
                showSlecStore && (
                    <>
                        {selectedAdress.street ?
                            <div className="fixed a z-10 flex justify- items-center bottom-[3%] left-[30%] bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded" role="alert">
                                <strong className="font-bold pr-2">Sorry. </strong>
                                <span className="block sm:inline"> We don't deliver in your area yet, please select a different address. </span>
                                <span onClick={() => { setShowSlecStore(false) }} className=" top-0 bottom-0 right-0 px-4 py-3">
                                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                    </svg>
                                </span>
                            </div>
                            :
                            <div className="fixed top-0 scrollbar overflow-y-auto right-0 h-full top-0 z-20 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                                <div className="relative bg-white rounded-lg shadow xl:w-1/3">
                                    <svg onClick={() => { setShowSlecStore(false) }} className='absolute top-3 end-2.5 cursor-pointer rounded-full hover:shadow-md' width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16.3242" cy="16.0781" r="16" fill="#FF4141" />
                                        <path d="M21.6654 10.7349C21.335 10.4045 20.7994 10.4045 20.469 10.7349L16.3242 14.8797L12.1793 10.7349C11.8489 10.4045 11.3133 10.4045 10.9829 10.7349C10.6526 11.0653 10.6526 11.6009 10.9829 11.9313L15.1278 16.0761L10.9829 20.221C10.6526 20.5513 10.6526 21.087 10.9829 21.4173C11.3133 21.7477 11.8489 21.7477 12.1793 21.4173L16.3242 17.2725L20.469 21.4173C20.7994 21.7477 21.335 21.7477 21.6654 21.4173C21.9957 21.087 21.9957 20.5513 21.6654 20.221L17.5205 16.0761L21.6654 11.9313C21.9957 11.6009 21.9957 11.0653 21.6654 10.7349Z" fill="white" />
                                    </svg>
                                    <div className="p-4 md:p-5 text-center ">
                                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                        <h3 className={" text-xl text-center mb-3 leading-6 font-bold text-gray-700"} id="modal-headline" >Check Availability</h3>
                                        <h3 className="mb-5  text-lg font-normal text-gray-700 ">check if this product is available in your area.</h3>
                                        <button onClick={() => { handelClose() }} data-modal-hide="popup-modal" type="button" className="text-white w-32 bg-orange-600 hover:bg-orange-800 focus:ring-0 focus:outline-none focus:ring-orange-300 dark:focus:ring-orange-600 font-medium rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center">Add Address</button>
                                        {/* <button onClick={() => CheqAGE("no")} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button> */}
                                    </div>
                                </div>
                            </div>
                        }</>
                )
            }
            {/* Age model */}
            {
                ageModal && !localStorage?.token && <div className="fixed top-0 scrollbar overflow-y-auto right-0 h-full top-0 z-20 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div className="relative bg-white rounded-lg shadow xl:w-1/3">
                        <button onClick={() => setAgeModal(false)} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                            <svg className="w-3 h-3 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center ">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="orange" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-700 ">Are you over 21?</h3>
                            <button onClick={() => CheqAGE("yes")} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">Yes, I'm</button>
                            <button onClick={() => CheqAGE("no")} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
}



export default Product;




